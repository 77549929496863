import React from 'react'
import logo from '../Images/EcoreClearBackground.png'
import '../ComponentsCss/Header.css'
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <div className="Header">
      <div className='headerLeft'>
        <Link to ="/">
            <img src={logo} alt="Ecore Logo" className='headerLeftOptions ecoreLogo'></img>
        </Link>
      </div>
      <div className='headerRight'>
        <Link to="/Dock10">
          <h4 className='headerRightOptions'>Dock 10</h4>
        </Link>
        <Link to="/Dock11">
          <h4 className='headerRightOptions'>Dock 11</h4>
        </Link>
      </div>
    </div>
  )
}