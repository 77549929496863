import './App.css';
import Home from './Components/Home'
import Header from './Components/Header';
import Dock10Body from './Components/Dock10Body';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Dock11Body from './Components/Dock11Body';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
            <Routes>
              <Route path ="/" element={<Home />} />
              <Route path ="/Dock10" element={<Dock10Body />}/>
              <Route path ="/Dock11" element={<Dock11Body />} />
           </Routes>
      </BrowserRouter>    
    </div>
  );
}

export default App;
