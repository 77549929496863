import React from 'react'
import Dock10 from '../Images/Dock10Map.jpg'
import '../ComponentsCss/Dock10Body.css'


export default function Dock10Body() {
  return (
    <div className='Dock10Body'>
        <h3>Ecore International Dock 10 Shipping Map</h3>
        <br/>
        <img src={Dock10} alt="Dock 10 Shipping Map" className="Dock10ShippingMap"></img>
    </div>
  )
}
