import React from 'react'
import Dock11 from '../Images/Dock11Map.jpg'
import '../ComponentsCss/Dock11Body.css'

export default function Dock11Body() {
  return (
    <div>
        <h3>Ecore International Dock 11 Shipping Map</h3>
        <br/>
        <img src={Dock11} alt="Dock 11 Shipping Map" className="Dock11ShippingMap"></img>
    </div>
  )
}
